import type { ModalProps } from "~/components/modal";
import type {
  KanbanCardFieldsFragment,
  CurrentUserFieldsFragment,
  TaskFieldsFragment,
  TeamFieldsFragment,
  TimesheetFieldsFragment
} from "~/types/api";
import { useState } from "react";
import Alert from "~/components/alert";
import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";
import {
  IconEdit,
  IconUnlock,
  IconLock,
  IconExclamation
} from "~/components/icons";
import Modal from "~/components/modal";
import Panel from "~/components/panel";
import TeamForm from "~/components/projects/team-form";
import { Buttons, RemixForm } from "~/components/remix-form";
import Table from "~/components/table";
import { useProject } from "~/contexts";
import { isAllowed, useCurrentUser } from "~/utils/auth";
import { formatDate } from "~/utils/dates";
import { teamIncludes } from "~/utils/projects";

interface Props {
  project: TeamFieldsFragment;
}

export const lockedProjectWarning = (
  <em className="text-gray-500">
    You don&apos;t have access to this project&apos;s information
  </em>
);

export const canAccessProject = (
  user: CurrentUserFieldsFragment,
  item?: TaskFieldsFragment | KanbanCardFieldsFragment | TimesheetFieldsFragment
) => {
  if (!item?.project) return true;

  return (
    !item.project.secured ||
    isAllowed("Admin/Developer", user) ||
    user.id === item?.user?.id ||
    teamIncludes(item.project, user.id)
  );
};

export default function ProjectTeam({ project }: Props) {
  const context = useProject();
  const currentUser = useCurrentUser();
  const [editing, setEditing] = useState(false);
  const [locking, setLocking] = useState(false);

  const emailTo = (user: { email?: string | null; fullname: string }) => (
    <a
      href={`mailto:${user.email}?subject=${project.name} (PFCS ${project.number})`}
    >
      {user.fullname}
    </a>
  );

  let users = [];

  if (project.mode === "Opportunity" && project.opportunityOwner) {
    users.push(project.opportunityOwner.email);
  }
  if (project.pm) {
    users.push(project.pm.email);
  }
  if (project.pc) {
    users.push(project.pc.email);
  }
  if (project.technicalLead) {
    users.push(project.technicalLead.email);
  }
  users = users.concat(project.projectUsers.map((pu) => pu.user.email));

  const pmLink = project.pm ? emailTo(project.pm) : "N/A";
  const pcLink = project.pc ? emailTo(project.pc) : pmLink;

  const tlLink = project.technicalLead ? emailTo(project.technicalLead) : "N/A";

  const experts = project.projectUsers.filter((pu) => pu.role === "Expert");
  const expertLinks = experts.length
    ? experts.map((e) => <div key={e.id}>{emailTo(e.user)}</div>)
    : "N/A";

  const renderOthers = () => {
    const users = project.projectUsers;
    const pOthers = users.filter((u) => u.role === "PA" || u.role === "Other");
    const tOthers = users.filter((u) => u.role === "TA" || u.role === "TC");
    const count = Math.max(pOthers.length, tOthers.length);

    const arr = Array.from(Array(count).keys());

    return arr.map((v) => {
      const f1 = pOthers[v] ? pOthers[v].role : "";
      const f2 = pOthers[v] ? emailTo(pOthers[v].user) : "";
      const f3 = tOthers[v] ? tOthers[v].role : "";
      const f4 = tOthers[v] ? emailTo(tOthers[v].user) : "";
      return (
        <tr key={v}>
          <td>{f1}</td>
          <td className="font-bold">{f2}</td>
          <td>{f3}</td>
          <td className="font-bold">{f4}</td>
        </tr>
      );
    });
  };

  const acknowledgement =
    context.confidentialityRequired &&
    context.acknowledgements.find((a) => a.user.id === currentUser.id);

  return (
    <Panel>
      <Panel.Header
        title={`${project.mode === "Campaign" ? "Campaign" : "Project"} Team`}
        button={
          <ButtonGroup className="print:hidden">
            <Button onClick={() => setLocking(true)} small>
              {project.secured ? <IconUnlock /> : <IconLock />}
            </Button>
            <Button onClick={() => setEditing(true)} small>
              <IconEdit />
            </Button>
          </ButtonGroup>
        }
      />
      <Alert mode="warning" className={project.secured ? undefined : "hidden"}>
        <IconLock large /> Access is restricted to the staff below.
      </Alert>
      <Table>
        <tbody>
          {project.mode === "Opportunity" && (
            <tr>
              <td>Opp. Owner</td>
              <td className="font-bold">
                {project.opportunityOwner
                  ? emailTo(project.opportunityOwner)
                  : "N/A"}
              </td>
              <td colSpan={2} />
            </tr>
          )}
          <tr>
            <td>{project.mode === "Campaign" ? "CC" : "PC"}</td>
            <td className="font-bold">{pcLink}</td>
            {project.mode === "Campaign" ? (
              <>
                <td>CM</td>
                <td className="font-bold">{pmLink}</td>
              </>
            ) : (
              <>
                <td>TL</td>
                <td className="font-bold">{tlLink}</td>
              </>
            )}
          </tr>
          {project.mode !== "Campaign" && (
            <tr>
              <td>PM</td>
              <td className="font-bold">{pmLink}</td>
              <td>Experts</td>
              <td className="font-bold">{expertLinks}</td>
            </tr>
          )}
          {renderOthers()}
        </tbody>
        {acknowledgement && (
          <tfoot>
            <tr className="warning">
              <td colSpan={4} className="text-center">
                Confidentiality Agreement accepted on{" "}
                {formatDate(acknowledgement.date)}
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      {editing && (
        <TeamForm source={project} onClose={() => setEditing(false)} />
      )}
      {locking && (
        <LockProject project={project} onClose={() => setLocking(false)} />
      )}
    </Panel>
  );
}

type LockProjectProps = ModalProps & {
  project: TeamFieldsFragment;
};

const LockProject = ({ project, onClose }: LockProjectProps) => {
  const currentUser = useCurrentUser();

  return (
    <Modal onClose={onClose}>
      <Modal.Header title={`${project.secured ? "Unlock" : "Lock"} Project`} />
      <RemixForm fetcher onSuccess={onClose}>
        <Modal.Body>
          <input type="hidden" name="id" value={project.id} />
          <input
            type="hidden"
            name="secured"
            value={project.secured ? "0" : "1"}
          />
          <p>
            Are you sure you want to {project.secured ? "unlock" : "lock"} this
            project?{" "}
            {project.secured
              ? "All project information will be viewable by anyone after unlocking."
              : "Only the project team will be able to view this project after locking."}
          </p>
          {!project.secured && !teamIncludes(project, currentUser.id) && (
            <Alert mode="danger">
              <IconExclamation fixed /> You are not on the project team, so you
              will be locked out of this project immediately!
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons
            modal
            primaryLabel={project.secured ? "Unlock" : "Lock"}
            savingLabel={project.secured ? "Unlocking..." : "Locking..."}
          />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
